html {
    scroll-behavior: smooth;
  }

body{
    // background-color: black;
    // overflow-x: hidden;
    font-family: 'Roboto';
}


$container: 1320px;

$color_1: #32B8C5; 
$color_2: #008195;
$color_3: #FFDD00;
$color_4: #48C266;
$color_5: #DD496A;
$color_6: #449746;

.full-width{
    width:100%;
}

.position-relative{
    position: relative;
}

.z-index-10{
    z-index: 10;
}

.watch-video-button{
display: inline-block;
padding: 15px 30px;
padding-right: 80px;
border-radius: 100px;
background-color: $color_2;
max-width: 400px;
color:#fff;
height: 64px;
position: relative;
font-family: 'Roboto';
font-weight: 500;
font-size: 24px;
text-decoration: none;
margin: 0 auto;
margin-top: 70px;
min-width: 265px;

@media screen and (max-width:1280px){
    transform: scale(0.7);
    margin-top: 30px;
}

&::after{
    position: absolute;
    background-image: url('../../dist/images/button-arrow.svg');
    background-repeat: no-repeat;
    background-position: 16px center;
    background-color: $color_3;
    content:"";
    width: 50px;
    height: 50px;
    right: 8px;
    border-radius: 25px;
    top: calc(50% - 25px);
    transition: background-color 0.2s ease-in-out;
}


&:hover, &:active{
    color:#fff;

    &::after{
        background-color: $color_4;
    }
    
}


}

header{
   
    height: 245px;
    position: fixed;
    z-index: 1;
    &::before{
        position: absolute;
        height: calc(100% - 1px);
        width: 50%;
        left:0px;
        top:0px;
        content: "";
        background-color: $color_2;
        z-index: -1;
        @media screen and (max-width:992px){
        display: none;
        }
    }

  

   



    .pour-cold-water-wrapper{
        padding-top: 15px;
    }

    .loyd-logo-wrapper{
        padding-top: 30px;
        padding-bottom: 30px;
        height: 100%;

        img{
            height: 185px;
        };
    }

    .menu-shape{
        position: absolute;
        right: 0px;
        height: 100%;
        z-index: -1;

        @media screen and (max-width:992px){
            display: none;
        }
    }


    &.shrink{

        background-color: $color_2;
        z-index: 99999;
     &::before{
        display: none;
     }   

     .menu-shape{
        display: none;
     }

     height: 100px;

     .loyd-logo-wrapper{
        padding-top: 10px;
        padding-bottom: 10px;
        img{
            height: 80px;
            margin: 0 auto;
            display: block;
        }
     }

     .pour-cold-water-wrapper{
        img{
            height: 75px;
        }
     }

    }

    @media screen and (max-width:1440px){
        height: 180px;
        .loyd-logo-wrapper{
            padding-top: 20px;
            padding-bottom: 20px;
            height: 100%;
    
            img{
                height: 140px;
            }
        }
    }

    @media screen and (max-width:992px){
        background-color: $color_2;
        z-index: 99999;
     &::before{
        display: none;
     }   

     .menu-shape{
        display: none;
     }

     height: 100px;

     .loyd-logo-wrapper{
        padding-top: 10px;
        padding-bottom: 10px;
        img{
            height: 80px;
            margin: 0 auto;
            display: block;
        }
     }

     .pour-cold-water-wrapper{
        img{
            height: 75px;
        }
     }
    }

    
}

.first-section{
    background-color: $color_1;
    height: 100vh;
    
    max-height: 1200px;

    @media screen and (max-width:768px){
        max-height: none;
        height: auto;
    }


    .mobile-glass-1{
        z-index: 5;
        position: relative;
        max-width: 70vw;
        margin-left: 0px;
        margin-right: 0px;
    }


    .glass-wrapper{
        height: 100vh;
        min-height: 600px;
        max-height: 1200px;
        z-index: 10;
        animation: glass 15s infinite;
        .glass{
            position: absolute;
            right: 0px;
            bottom: 0px;
            width: auto;
            max-height: calc(100vh - 100px);
            max-width: 100%;
        }

       
    }

    .wrapper.icetea-logo{
        img{
            width:100%;
            max-width: 364px;
            margin-top: 200px;
            max-height: 45vh;
        }
    }

   

    .white-area{
        position: absolute;
        top:0px;
        left:0px;
        width: calc((100vw - $container) / 2 + ($container / 3));
        background-color: #fff;
        height: 100%;
        z-index: 0;
        
        img{
            position: absolute;
            height: 100%;
            left: calc(100% - 2px);
            top: 0px;

            @media screen and (max-width:768px){
                img{
                    left: calc(100% - 42px);
                 }
            }

            
        }

        @media screen and (max-width:992px){
           width:50vw;
         }

         
    }

    .flash-1{
        position:absolute;
        width: 40%;
        top:0px;
        right: 0px;

        img{
            width:100%;
        }
    }
}

.second-section{
    padding-top: 145px;
    padding-bottom: 145px;
    background: linear-gradient(252.41deg, #D2DB5C 17.01%, #F2FF4A 90.9%);

    img{
        @media screen and (max-width:992px){
            margin-left:auto;
            margin-right:auto;
            display: block;
        }
    }

        
    @media screen and (max-width:992px){
       padding-bottom: 55px;
    }
}



.third-section{
    padding-top: 145px;
    padding-bottom: 145px;
    background: linear-gradient(129.77deg, #E6776E 0%, #FCBFBA 50.82%, #FF6B60 100%);

    @media screen and (max-width:768px){
        padding-bottom: 0px;
     }
    
    .glass-2{
        position: absolute;
        bottom: 0px;
        right: 0px;
       
        z-index: 5;

        @media screen and (max-width:992px){
            width: 415px;
        }
    }    

    .white-2{
        position: absolute;
        height: 100%;
        left:0px;
        top:0px;
        overflow: hidden;
        img{
            
        };
    }

    p{
        color:#fff;
        font-family: 'Roboto','sans-serif';
        font-weight: 500;
        font-size: 20px;
        line-height: 1.5em;
    }
}

.fourth-section{
    // background: rgb(22,170,186);
    // background: linear-gradient(90deg, rgba(22,170,186,1) 50%, rgba(72,194,102,1) 50%);
    overflow: hidden;
    
    min-height: 400px;
}

.product-sections{
    
    .product-item{

        
       &.cytryna-z-limonka{
        
        padding-right: 45px;
        position: relative;
        &:before{
            position: absolute;
            width: 50vw;
            height: 100%;
            right: 0vw;
            z-index: -1;
            background-color: $color_2;
            content: "";
        }
       }

       &.jablko-i-ananas{
        padding-left: 45px;
        position: relative;
        &:before{
            position: absolute;
            width: 50vw;
            height: 100%;
            left: 0vw;
            z-index: -1;
            background-color: #da6f00;
            content: "";
        }
        
       }

       &.pomarancza-i-szalwia{
        padding-left: 45px;
        position: relative;
        &:before{
            position: absolute;
            width: 50vw;
            height: 100%;
            left: 0vw;
            z-index: -1;
            background-color: #7c2b9f;
            content: "";
        }
        
       }

       &.truskawka-i-malina{       
        padding-right: 45px;
        position: relative;
        &:before{
            position: absolute;
            width: 50vw;
            height: 100%;
            right: 0vw;
            z-index: -1;
            background-color: #a03784;
            content: "";
        }       
       }

       &.watch-product-video{
        padding-left: 12%;
        padding-right: 12%;
        position: relative;
        padding-bottom: 80px;
        &:before{
            position: absolute;
            width: 50vw;
            height: 100%;
            left: 0vw;
            z-index: -1;
            background-color: $color_1;
            content: "";
        }
    }

    @media screen and (max-width:992px){
        text-align: center;
        padding-right: 20px;
        padding-left: 20px;
        position:inherit !important;

        &:before{
            width: 100vw !important;
            left:0px;
        }

        p{
            text-align: center;
        }

        h3{
            text-align: center;
        }

        h2{
            text-align: center;
        }

        img{
            margin-left: auto;
            margin-right: auto;
        };

        .product-content{
            padding-right: 0px !important;
        }
    }
    }
    
    .product-image{
        padding-top: 145px;
        padding-bottom: 30px;
    }

    p.product-content{
        padding-bottom: 90px;
    }

    .product-content{
        padding-right: 50px;
        color:#fff;

        
        
    }
}

.fifth-section{
    padding-top:200px;
    padding-bottom: 155px;
    background: linear-gradient(252.41deg, #D2DB5C 17.01%, #F2FF4A 90.9%);
    min-height: 500px;

    @media screen and (max-width:992px){
        padding-top: 100px;
        
    }

    h2{
        font-weight: 500;
        font-size: 48px;
        line-height: 60px;
        color: $color_6;
        text-align: center;
        margin-bottom: 30px;
    }

    p{
        font-weight: 500;
        font-size: 20px;
        line-height: 30px;
        /* or 150% */
        text-align: center;
        color: $color_6;
        margin-bottom: 100px;
    }

    .nature-item{
        margin-bottom: 40px;
    }

    .nature-item .wrapper{
        padding-top: 50px;
        padding-bottom: 50px;
        padding-left: 25px;
        padding-right: 25px;
        // min-height: 300px;
        border-radius: 32px;
        background-color: #fff;
        height: 100%;
       

        h3{
            font-size: 32px;
            line-height: 60px;
            /* identical to box height, or 188% */
            text-align: center;
            font-weight: 500;
            color: $color_2;
            margin-bottom: 25px;
        }

        p{
            font-size: 20px;
            line-height: 30px;
            /* or 150% */
            text-align: center;
            font-weight: 400;
            color:$color_2;
            margin-bottom: 0px;
        }
    }

}

.sixth-section{
    padding-top: 140px;
    padding-bottom: 140px;
    min-height: 200px;
    background: linear-gradient(120.32deg, #22CAFE 25.99%, #01EDFE 63.36%, #24E6FC 105.72%);
    @media screen and (max-width:992px){
        padding-bottom: 60px;
        
    }
    
    p.contact-content{
        font-weight: 500;
        font-size: 20px;
        line-height: 30px;
        color:#fff;

        a{
            text-decoration: none;
            color:#fff;
            &.website{
                color:$color_2;
            }
        }
    }

    h2{
        font-weight: 500;
        font-size: 48px;
        line-height: 60px;
        /* or 125% */
        text-align: center;
        color: #FFFFFF;
        margin-bottom: 65px;
    }

    .social-media-icon{
        text-decoration: none;
        &.fb{
            margin-right: 10px;
        }
    }
}

.floating-element{
    position: absolute;
    z-index: 50;

    transition: transform 0.2s linear;

    

   

    &.s1-lemon{
        right: 10px;
        top:130px;

        @media screen and (max-width:1280px){
            display: none;
        }


    }

    &.s1-mint{
        left: calc((100vw - $container) / 2 + ($container / 12));
        bottom: -100px;

        @media screen and (max-width:992px){
            display: none;
        }
    }

    &.s1-ice{
        right: calc((100vw - $container) / 2 + (($container / 12) * 3));
        bottom: -70px;

        @media screen and (max-width:992px){
            display: none;
        }
    }

    &.s2-ice{
        left: calc((100vw - $container) / 2 + ($container / 12));
        bottom: -100px;

        @media screen and (max-width:992px){
            display: none;
        }
    }

    &.s3-mint{
        top: calc(50% - 60px);
        left: calc(50% - 160px);
        animation: mint-rotation 20s infinite;
        @media screen and (max-width:992px){
            top: calc(50% - 20px);
        }
    }

    &.s3-lemon{
        top: calc(75% - 100px);
        left: calc(50% - 85px);
        animation: lemon-rotation 15s infinite;

        @media screen and (max-width:992px){
            top: calc(75% - 0px);
        }
    }

    &.s3-pineapple{
        top:calc(50% - 70px);
        right: 0px;
        @media screen and (max-width:1280px){
            display: none;
        }

        @media screen and (max-width:992px){
            display: none;
        }
    }

    &.s4-strawberry{
        top:-160px;
        left:0px;

        @media screen and (max-width:1440px){
            width:300px;
            top:-100px;
        }

        

        @media screen and (max-width:1280px){
            display: none;
        }
    }

    &.s4-ice{
        top: -90px;
        left: calc(50% - 93px);

        @media screen and (max-width:992px){
            display: none;
        }
    }

    &.s4-mint{
        right: 4%;
        top: -130px;

        @media screen and (max-width:992px){
            display: none;
        }
    }

    &.s5-lemonice{
        left: 4%;
        top:-100px;
        max-width: calc(80vw);
    }
}

@keyframes mint-rotation {
    0% { transform: rotate(0deg); }
    25% { transform: rotate(25deg); }
    50% { transform: rotate(0deg); }
    75% { transform: rotate(-25deg); }
    100% { transform: rotate(0deg); }
}


@keyframes lemon-rotation {
    0% { transform: rotate(0deg); }
    25% { transform: rotate(-15deg); }
    50% { transform: rotate(0deg); }
    75% { transform: rotate(15deg); }
    100% { transform: rotate(0deg); }
}

@keyframes glass {
    0% { transform: translateX(0deg); }
    25% { transform: translateX(30px); }
    50% { transform: translateX(0deg); }
    75% { transform: translateX(-30px); }
    100% { transform: translateX(0deg); }
}

.modal-body{
    video{
        width: 100% !important;
    }
}

.modal{
    z-index: 999999;
}

.modal-lg, .modal-xl {
    max-width: 90%;
}

.video-section{
    video{
        max-width: 100%;
        box-shadow: 0px 0px 60px rgb(0 0 0 / 20%);
    }

    padding-top:100px;
    padding-bottom: 100px;

    background: linear-gradient(120.32deg, #22CAFE 25.99%, #01EDFE 63.36%, #24E6FC 105.72%);
}